import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import Async, { useAsync } from 'react-select/async';
import AsyncSelect from 'react-select/async';

import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';

import { AEFIService } from '../../../../services/CaseEntry/AEFIService';
import { AEService } from '../../../../services/CaseEntry/AEService';
import { SummaryService } from '../../../../services/QualityReview/Summary/SummaryService';
import { Role, Status } from '../../../../helpers/Constants';

import moment from "moment";

import { withTranslation } from "react-i18next";

class CaseInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            caseNumberList : []
        };
    }

    componentDidMount() {
        if(this.props.formType === undefined){
            Promise.all([AEFIService.Read("", 1, 10, "", "", "", "", "", "", "", "", "", "", "", "", true), AEService.Read("", 1, 10, "", "", "", "", "", "", "", "", "", "", "", "", true)])
            .then(([kipi, ktd]) => {

                this.setState({
                    caseNumberList: [...kipi.data?.records, ...ktd.data?.records],
                });
            });
        }else{
            Promise.all([AEFIService.Read("", 1, 10, ""), AEService.Read("", 1, 10, "",)])
            .then(([kipi, ktd]) => {

                this.setState({
                    caseNumberList: [...kipi.data?.records, ...ktd.data?.records],
                });
            });
        }
        
    }

    componentDidUpdate() { }

    handleSelectChange = (item, formik) => {
        let data = [];
        let dataSummary = [];

        if (
            this.props.formType === undefined ||
            (this.props.formType !== "edit" && this.props.isLastVersion === null) ||
            (this.props.formType !== "edit" && this.props.isLastVersion)
        ) {            
            let index = this.state.caseNumberList.map((x) => x.caseNumber).indexOf(item.value);
            data = this.state.caseNumberList[index];

            try {
                SummaryService.GetBatch(data?.id).then(result => {

                    dataSummary = result?.data;

                    let dataProduct = dataSummary?.medicalReview?.analysis?.analysisProduct ? dataSummary?.medicalReview?.analysis?.analysisProduct : [];
                    let dataProductSameBatch = dataSummary?.productSameBatch ? dataSummary?.productSameBatch : [];
                    let dataQualityReview = dataSummary?.medicalReview?.qualityReview ?? [];

                    formik.setFieldValue('analysisBy', dataSummary?.medicalReview?.analysis?.analysisBy ? dataSummary?.medicalReview?.analysis?.analysisBy : '')
                    formik.setFieldValue('analysisByLabel', dataSummary?.medicalReview?.analysis?.analysisByNavigation ? dataSummary?.medicalReview?.analysis?.analysisByNavigation?.name : '')
                    formik.setFieldValue('analysisByProvince', dataSummary?.medicalReview?.analysis?.analysisByProvince ? dataSummary?.medicalReview?.analysis?.analysisByProvince : '')
                    formik.setFieldValue('analysisByProvinceLabel', dataSummary?.medicalReview?.analysis?.analysisByProvinceNavigation ? dataSummary?.medicalReview?.analysis?.analysisByProvinceNavigation?.name : '')
                    formik.setFieldValue('chronology', dataSummary?.medicalReview?.analysis?.chronology ? dataSummary?.medicalReview?.analysis?.chronology : '')
                    formik.setFieldValue('attachmentAe', dataSummary?.caseEntry?.aeCaseEntry ? true : false)
                    formik.setFieldValue('attachmentAefi', dataSummary?.caseEntry?.aefiCaseEntry ? true : false)
                    formik.setFieldValue('attachmentAefiNonSerious', dataSummary?.reportSubmission?.bpom?.aefiNonSerious ? true : false)
                    formik.setFieldValue('attachmentAefiOverseas', dataSummary?.reportSubmission?.bpom?.aefiOverseas ? true : false)
                    formik.setFieldValue('attachmentAnalysisAe', dataSummary?.medicalReview?.analysis?.caseType === 'ae' ? true : false)
                    formik.setFieldValue('attachmentAnalysisAefiComplete', dataSummary?.medicalReview?.analysis?.caseType === 'aefi-complete' ? true : false)
                    formik.setFieldValue('attachmentAnalysisAefiIncomplete', dataSummary?.medicalReview?.analysis?.caseType === 'aefi-incomplete' ? true : false)
                    formik.setFieldValue('attachmentBpom', dataSummary?.reportSubmission?.bpom?.progress ? true : false)
                    formik.setFieldValue('attachmentManufactureGrowth', dataSummary?.reportSubmission?.manufacture?.growth ? true : false)
                    formik.setFieldValue('attachmentManufactureInitial', dataSummary?.reportSubmission?.manufacture?.initial ? true : false)
                    formik.setFieldValue('attachmentPartner', dataSummary?.reportSubmission?.partner ? true : false)
                    formik.setFieldValue('attachmentReporter', dataSummary?.reportSubmission?.reporter ? true : false)
                    formik.setFieldValue('attachmentResume', dataSummary?.medicalReview?.resume ? true : false)
                    formik.setFieldValue('bprReview', dataQualityReview?.bprReview)
                    formik.setFieldValue('summaryBatchReview', dataQualityReview?.summaryBatchReview)
                    formik.setFieldValue('investigationReview', dataQualityReview?.investigationReview)
                    formik.setFieldValue('summaryProductAnalysis', dataProduct?.map((item, index) => {
                        return {
                            product: item.vaccine ?? item.drugs,
                            productName: item.productName,
                            batch: item.batch,
                            causalityUpv: item.causalityUpvNavigation?.id,
                            fieldAnalysisUpvText: item.causalityUpvNavigation?.name,
                            causalityExternal: item.causalityExternalNavigation?.id,
                            fieldAnalysisExtText: item.causalityExternalNavigation?.name,

                            summaryProductAnalysisUpv: item.analysisCausalityUpv.map((causality, index) => {
                                return {
                                    causality: causality.causalityNavigation?.id,
                                    sidelight: causality?.sidelight,
                                    causalityAnalysisText: causality.causalityNavigation?.name,
                                }
                            }),

                            summaryProductAnalysisExternal: item.analysisCausalityExternal.map((causality, index) => {
                                return {
                                    causality: causality.causalityNavigation?.id,
                                    sidelight: causality?.sidelight,
                                    causalityAnalysisText: causality.causalityNavigation?.name,
                                }
                            }),
                        };
                    }));

                    formik.setFieldValue('summarySameBatchProduct', dataProductSameBatch.map((item, index) => {

                        let caseEntry = item?.aefiVaccineNavigation ?? item?.aeDrugsNavigation;
                        let complaint = (caseEntry.caseEntryNavigation?.aefiAllergic || caseEntry.caseEntryNavigation?.aefiSymptomps) ? (caseEntry.caseEntryNavigation?.aefiSymptomps?.map(item => { return item.symptompsNavigation?.name }).join(", ") + (caseEntry.caseEntryNavigation?.aefiAllergic?.length > 0 ? item.aefiAllergic?.map(item => { return item.allergicNavigation?.name }).join(", ") : "")) : (caseEntry?.reaction ? caseEntry?.reation : "-");
                        return {
                            caseEntry: caseEntry?.caseEntry,
                            caseNumber: caseEntry?.caseEntryNavigation?.caseNumber,
                            patientName: caseEntry?.caseEntryNavigation?.patientName,
                            product: caseEntry?.vaccineNavigation?.id ?? caseEntry?.drugsNavigation?.id,
                            productName: caseEntry?.vaccineNavigation?.name ?? caseEntry?.drugsNavigation?.name,
                            batch: caseEntry?.batch,
                            complaint: complaint,
                            analysis: item?.analysis,
                            analysisResult: item?.analysisNavigation ? item?.analysisNavigation?.analysisProduct[0]?.causalityExternalNavigation?.alias + " - " + item?.analysisNavigation?.analysisProduct[0]?.causalityUpvNavigation?.alias : "",
                            analysisReason: item?.analysisNavigation?.analysisProduct[0]?.causalityReasons ?? ""
                        };
                    }));
                });

                let complaint = (data?.aefiAllergic || data?.aefiSymptomps) ? (data?.aefiSymptomps?.map(item => { return item.symptompsNavigation?.name }).join(", ") + (data?.aefiAllergic?.length > 0 ? ', ' + data?.aefiAllergic?.map(item => { return item.allergicNavigation?.name }).join(", ") : "")) : (data?.reaction ? data?.reaction : "-");

                let caseEntryProduct = data?.aefiVaccine ?? data?.aeDrugs ?? [];

                formik.setFieldValue('caseNumber', item.value);
                formik.setFieldValue('caseEntry', data?.id ? data?.id : "");
                formik.setFieldValue('patientName', data?.patientName ? data?.patientName : "");
                formik.setFieldValue('patientAgeOfMonth', data?.patientAgeMonth ? data?.patientAgeMonth : '')
                formik.setFieldValue('patientAgeOfYear', data?.patientAgeYear ? data?.patientAgeYear : '')
                formik.setFieldValue('reporterName', data?.reporterName ? data?.reporterName : '')
                formik.setFieldValue('seriousness', data?.seriousness ? data?.seriousness : '')
                formik.setFieldValue('seriousnessLabel', data?.seriousnessNavigation ? data?.seriousnessNavigation?.alias : '')
                formik.setFieldValue('adversePvDate', data?.adversePvDate ? moment(data?.adversePvDate, "YYYY-MM-DD").format('YYYY/MM/DD') : '')
                formik.setFieldValue('chronology', data?.resultChronology ? data?.resultChronology : "")
                formik.setFieldValue('complaint', complaint)
                formik.setFieldValue('diagnosis', data?.resultConclusion ?? '')
                formik.setFieldValue('caseEntryType', data?.aefiVaccine ? 'AEFI' : 'AE')
                formik.setFieldValue('summaryProduct', caseEntryProduct.map((item, index) => {

                    let product = item?.vaccineNavigation ?? item?.drugsNavigation;

                    return {
                        product: item?.vaccine ?? item?.drugs,
                        productName: product?.name,
                        batch: item?.batch,
                        expiredDate: item.expiredDate ? item.expiredDate : null
                    };
                }));

                formik.setFieldValue('summaryProductQualityReview', caseEntryProduct.map((item, index) => {

                    let product = item?.vaccineNavigation ?? item?.drugsNavigation;

                    return {
                        product: item?.vaccine ?? item?.drugs,
                        productName: product?.name,
                        batch: item?.batch,
                        expiredDate: item.expiredDate ? item.expiredDate : null
                    };
                }));

            } catch (error) {
                console.error("An error occurred:", error);
            }
        }
    }

    debounce = (func, delay) => {
        let inDebounce;
        return (...args) => {
            return new Promise(resolve => {
                clearTimeout(inDebounce);
                inDebounce = setTimeout(() => resolve(func(...args)), delay);
            });
        };
    };

    promiseOptions = this.debounce(async (inputValue) => {
        try {
            const [kipi, ktd] = await Promise.all([
                AEFIService.Read("", 1, 10, inputValue, "", "", "", "", "", "", "", "", "", "", "", true),
                AEService.Read("", 1, 10, inputValue, "", "", "", "", "", "", "", "", "", "", "", true),
            ]);

            const combinedData = [...kipi.data.records, ...ktd.data.records].map((item) => {
                return { value: item.caseNumber, label: item.caseNumber, id: item.id };
            });
            this.setState({ caseNumberList: [...kipi.data.records, ...ktd.data.records] })

            return combinedData;
        } catch (error) {
            console.error("Error fetching data", error);
            return [];
        }
    }, 500);

    render() {

        const { formik, t, currentUser } = this.props;

        const role = currentUser ? currentUser.role : "";
        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion) || (this.props.formType !== "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || (this.props.formType === "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || this.props.isLastVersion === undefined || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed && this.props.isLastVersion) ? false : this.props.isLastVersion === false || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved ;

        const disabled = this.props.isLastVersion === undefined  ? false : this.props.formType === "edit" || this.props.isLastVersion !== undefined;
        const isNew = this.props.isLastVersion === undefined ? true : false;

        let filteredCaseNumber = this.state.caseNumberList.reduce(function (filtered, item) {
            if (item.caseNumber) {
                var someNewValue = { value: item.caseNumber, label: item.caseNumber }
                filtered.push(someNewValue);
            }
            return filtered;
        }, []);

        return (
            <div className="tab-pane show active tab-pane-fixed" id="case-information">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">{t('case_number')} <span style={{color: "red"}}>*</span></label>
                                {
                                    this.props.formType === undefined ? (
                                        <AsyncSelect                                            
                                            defaultOptions={isNew ? filteredCaseNumber : this.state.caseNumberList?.map(item => { return { value: item.caseNumber, label: item.caseNumber }; })}
                                            loadOptions={this.promiseOptions}
                                            cachedOptions={false}                                            
                                            onChange={(selectedOption) => this.handleSelectChange(selectedOption, formik)}
                                            onBlur={formik?.setFieldTouched}
                                            disabled={disabled}
                                        />
                                    ) : (
                                        <Input
                                            disabled
                                            value={formik?.values?.caseNumber}
                                            name="caseNumber"
                                            type="text"
                                            errors={formik?.errors}
                                            touched={formik?.touched}
                                        />
                                    )
                                }
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('patient_name')}</label>
                            <Input
                                name="patientName"
                                type="text"
                                placeholder={t('patient_name')}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik?.values?.patientName}
                                {...formik.getFieldProps('patientName')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('complaint')}</label>
                            <Input
                                name="complaint"
                                type="text"
                                placeholder={t('complaint')}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik?.values?.complaint}
                                {...formik.getFieldProps('complaint')}
                                disabled
                            />
                        </div>
                        <div>
                            <label className="form-label">{t('age')}</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input
                                                name="patientAgeOfYear"
                                                type="number"
                                                min={0}
                                                placeholder={t('year')}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values?.patientAgeOfYear ? formik.values?.patientAgeOfYear : "" }
                                                {...formik.getFieldProps('patientAgeOfYear')}
                                                disabled
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('year')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input
                                                name="patientAgeOfMonth"
                                                type="number"
                                                min={0}
                                                max={11}
                                                placeholder={t('month')}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('patientAgeOfMonth')}
                                                disabled
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('month')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">{t('diagnose')}</label>
                            <Input
                                name="diagnosis"
                                type="text"
                                placeholder={t('diagnose')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('diagnosis')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('reported_date')}</label>
                            <DatePicker 
                                name="adversePvDate"
                                selectionType="single"
                                maxdate={new Date()}
                                value={formik.values?.adversePvDate}
                                errors={formik.errors}
                                touched={formik.touched}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('sender')}</label>
                            <Input
                                name="reporterName"
                                type="text"
                                placeholder={t('sender')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('reporterName')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('case_category')}</label>
                            <Input
                                name="seriousnessLabel"
                                type="text"
                                placeholder={t('case_category')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('seriousnessLabel')}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <hr/>
                        <div className="form-group">
                            <label className="form-label">{t('product_table')}</label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>{t('product_name')}</th>
                                        <th>{t('batch_number')}</th>
                                        <th>{t('expired_date')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formik.values?.summaryProduct?.map((record, index) =>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                                {record?.productName}
                                            </td>
                                            <td>{record?.batch}</td>
                                            <td>
                                                {record?.expiredDate ? moment(record?.expiredDate).format('DD/MM/yyyy') : '-'}
                                            </td>
                                        </tr>
                                    )}
                                    {
                                        (formik.values?.summaryProduct === undefined || formik.values?.summaryProduct === null || formik.values?.summaryProduct?.length === 0) && (
                                            <tr>
                                                <td colSpan="4" style={{textAlign:"center"}}>
                                                    {t('data_not_found')}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(CaseInformation);